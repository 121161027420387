.founder-msg-container {
  display: flex;
  margin-top: 60px;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 50px;
  background: linear-gradient(180deg, #fff, #dfdbfe);
  padding-top: 30px;
  padding-bottom: 60px;
}

.founder-msg-container h3 {
  font-size: 25px;
}

.founder-msg-container p {
  font-family: "Anek Bangla", sans-serif;
  margin-top: 25px;
  opacity: 0.9;
}

.p {
  display: flex;
  align-items: center;
  justify-content: left;
}

.founder-content {
  width: 600px;
  border: 2px solid #dfdbfe;
  padding: 10px;
  border-radius: 5px;
}

.founder-img {
  height: 400px;
  width: 400px;
  background: #172554;
  border-radius: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: -100;
  overflow-y: hidden;

  /* border: 5px solid #d97706; */
}

.founder-image-border {
  border: 30px solid #172554;
  border-radius: 50%;
  padding: 20px;
}

.founder-img img {
  height: 120%;
  /* scale: 1.7; */
  /* z-index: -1; */
}

.founderImage-container h1 {
  text-align: center;
  font-size: 35px;
  margin-top: 20px;
}
.founderImage-container h3 {
  text-align: center;
  font-size: 25px;
}

.founder-link {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 25px;
  font-size: 20px;
}

.founder-link-home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  font-size: 20px;
}

@media (max-width: 980px) {
  .founder-msg-container {
    flex-direction: column;
  }

  .founder-img {
    height: 300px;
    width: 300px;
  }

  .founder-content {
    width: 100%;
    border: 2px solid #dfdbfe;
    padding: 10px;
    border-radius: 5px;
  }

  .founder-content h3{
    text-align: center;
  }

  .founder-msg-container {
    align-items: center;
  }
}

@media (max-width: 400px) {
  .founder-img {
    height: 220px;
    width: 220px;
  }
}

/* ===========HOME BLOG DETAILS============== */

.home-blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  flex-wrap: wrap;
  margin-top: 70px;
}

.home-blog-card {
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border: 2px solid #dfdbfe;
  padding: 10px;
  background: #e1e1e1;
  border-radius: 5px;
}

.home-blog-content {
  width: 300px;
}

.home-blog-content p {
  font-family: "Anek Bangla", sans-serif;
}
.home-blog-content button {
  text-decoration: underline;
  color: #172554;
  margin-top: 20px;
}

.home-blogs-headers h1 {
  text-align: center;
  font-size: 35px;
  margin-top: 60px;
  color: #172554;
}

@media (max-width: 980px) {
  .home-blog-card {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    border: 2px solid #dfdbfe;
    padding: 10px;
    background: #e1e1e1;
    border-radius: 5px;
  }
}


/* ================================ */

.sdg-main{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 0px 60px;
  margin-top: 60px;
}

.sdg-main h1{
  font-size: 40px;
  color: #172554;
  margin-bottom: 35px;
  line-height: 1;
}
.sdg-main h1 span{
  font-size: 35px;
}

.sdg-main p{
  font-family: "Anek Bangla", sans-serif;
  text-align: justify;
  font-size: 22px;
}

@media (max-width:980px) {
  .sdg-main{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    padding: 0px 10px;
    margin-top: 60px;
    flex-wrap: wrap;
  }
  .sdg-main h1{
    font-size: 35px;
    color: #172554;
    margin-bottom: 35px;
    line-height: 1.2;
    text-align: center;
  }
  .sdg-main h1 span{
    font-size: 30px;
  }
}