.team-page {
  margin-top: 100px;
}

.founderImage-container {
  position: sticky;
  top: 0px;
}

.founder-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #dfdbfe;
  border-radius: 5px;
  padding-bottom: 30px;
  margin-top: 50px;
}

.founder-image {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #dfdbfe;
}
.advisor-image{
  height: 250px;
  width: 250px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #dfdbfe;
}

.founder-image img {
  width: 100%;
  border-radius: 50%;
}

.founder-content-team {
  margin-left: 60px;
}

.founder-content-team h1 {
  font-size: 40px;
}

.founder-content-team h4 {
  font-size: 30px;
  font-family: "Anek Bangla", sans-serif;
}
.founder-content-team button {
  /* font-size: 30px; */
  background: #172554;
  padding: 3px 20px;
  border-radius: 5px;
  color: #dfdbfe;
}

@media (max-width: 980px) {
  .founderImage-container {
    position: relative;
    top: 0px;
  }

  .founder-image {
    height: 200px;
    width: 200px;
  }
  .founder-cart {
    flex-direction: column;
  }
  .founder-content-team h1 {
    font-size: 40px;
    text-align: center;
  }
  .founder-content-team {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

/* ==============TEAM-SECTION============ */

.team-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  gap: 30px;
  margin-top: 60px;
  align-items: flex-start;
}

.team-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 400px;
  border: 2px solid #dfdbfe;
  padding: 10px;
  border-radius: 10px;
  background: #f1f1fe;
}

.team-image {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #dfdbfe;
}

.team-image img {
  width: 100%;
  border-radius: 50%;
}

.team-content-team {
  width: 200px;
}

.team-content-team h1 {
  font-size: 30px;
}
.team-content-team h4 {
  font-size: 20px;
  font-family: "Anek Bangla", sans-serif;
}
.team-content-team p {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

.founder-mail {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.fouder-message {
  text-align: justify;
}

@media (max-width: 980px) {
  .team-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .team-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    width: 100%;
    border: 2px solid #dfdbfe;
    padding: 10px;
    border-radius: 10px;
    background: #f1f1fe;
    text-align: center;
  }
  .team-content-team p {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
}
