.blog-page {
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.blog-main {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
  width: 90%;
}

.blog-cointainer {
  width: 210px;
  border: 2px solid #1f222e;
  background: #11141f;
  border-radius: 5px;
}
.blog-image-container {
  height: 140px;
  width: 100%;
  overflow: hidden;
  padding: 5px;
}
.blog-details-container {
  padding: 5px;
}
.blog-details-container h4 {
  color: #ddd;
  border-bottom: 1px solid #c8a143;
  padding-bottom: 6px;
}
.blog-details-container p {
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7px;
}
.blog-details-container p span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
}

.single-blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;
}

.single-blog-p {
  width: 450px;
  border-bottom: 2px solid #d1d5db;
}
.single-blog-p p {
  font-family: "Anek Bangla", sans-serif;
}


.single-blog-w-name{
  color: #172554;
  font-size: 20px;
  border-bottom: 2px solid #d1d5db;
}

.single-blog-image {
  width: 450px;
  margin-top: 50px;
}

.single-blog-share{
  display: flex;
  align-items: center;
  justify-content: left;
  width: 450px;
  margin-top: 20px;
  padding-top: 10px;
  gap: 15px;
}


.single-blog-image img {
  width: 100%;
  min-height: 300px;
}

.load-more-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  border-top: 2px solid #d1d5db;
  border-bottom: 2px solid #d1d5db;
  padding: 10px;
  background: #fefefe;
}

.load-more-btn button {
  width: 250px;
  height: 40px;
  background: #c8a143;
  border-radius: 5px;
}

@media (max-width: 980px) {
  .single-blog-p {
    width: 100%;
    padding: 10px;
  }

  .single-blog-image {
    width: 100%;
  }

  .blog-main {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }
  .single-blog-share{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin-top: 20px;
    padding: 5px;
    gap: 15px;
  }
}
