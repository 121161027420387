.our-story {
  margin-top: 40px;
}
.our-story h1 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 25px;
  color: #172554;
}

.our-story p {
  text-align: center;
  padding: 0px 100px;
  font-size: 22px;
  color: #1f2937;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 980px) {
  .our-story p {
    padding: 0px 20px;
    font-size: 22px;
    text-align: justify;
  }
}

/* ===========MAIN VALUES */

.our-values{
  margin-top: 60px;
  text-align: center;
  font-size: 35px;
  color: #172554;
}

.main-value {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  position: relative;
}

.main-value h1 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 25px;
  color: #172554;
}

.values-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  width: 100%;
}

.value-box {
  background: #e1e1e1;
  border: 2px solid #d1d5db;
  width: 500px;
  border-radius: 5px;
  padding: 5px;
  height: 300px;
}

.values-circle {
  height: 35px;
  width: 35px;
  background: #bfdbfe;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #172554;
  margin-top: 10px;
  border-radius: 50%;
}

.values-circle p:hover {
  rotate: -90deg;
}

.value-box h2 {
  font-size: 25px;
  margin: 5px 0px;
  border-bottom: 2px solid #d1d5db;
  margin-bottom: 20px;
}

.value-box p {
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 980px) {
  .main-value {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 30px;
  }
  .values-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
  }
  .value-box {
    background: #e1e1e1;
    border: 2px solid #d1d5db;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    height: 400px;
  }
  .main-values-circle {
    z-index: -10;
    animation: animemd 4s infinite alternate;
    /* display: none; */
  }
}

/* =================== */
.main-values-circle {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  background: #93c5fd;
  filter: blur(20px);
  animation: anime 4s infinite alternate;
  position: absolute;
  bottom: 20;
  left: 20;
  z-index: -10;
}

@keyframes anime {
  100% {
    border: 2px solid #2e51ed;
    opacity: 1;
    background: #3b82f6;
    transform: translate(100px, 100px);
  }
}

@media (max-width: 980px) {
  .main-values-circle {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background: #93c5fd;
    filter: blur(20px);
    animation: anime 4s infinite alternate;
    position: absolute;
    top: 0;
  }

  @keyframes anime {
    100% {
      border: 2px solid #2e51ed;
      opacity: 1;
      background: #2e51ed;
      transform: translateY(100vh);
    }
  }
}

/* ===============HEADER-HOME==========  */

.header-home {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.details {
  width: 80%;
  /* background: #dbeafe; */
  margin-top: 100px;
  padding: 20px;
  border-radius: 10px;
  height: 70vh;
}

.details h1 {
  text-align: center;
  font-size: 60px;
  z-index: -10;
}

.details h2 {
  text-align: center;
  font-size: 55px;
  color: #1f2937;
}

.header-home-circle {
  height: 400px;
  width: 400px;
  background: #6ee7b7;
  position: absolute;
  border-radius: 50%;
  top: -50%;
  z-index: -100;
  filter: blur(70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* =========MISSION=============  */

.mission-container {
  width: 100%;
  background: linear-gradient(180deg, #dfdbfe, #fff);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  border-radius: 20px;
  padding: 20px;
  margin-top: 60px;
}
.mission-card {
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between; */
  /* height: 380px; */
  /* background: #6ee7b7; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
  width: 500px;
  padding: 10px;
  border-radius: 15px;
}
.mission-tittle h1 {
  text-align: center;
}

.mission-p {
  width: 100%;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}
.mission-card h1 {
  font-size: 25px;
  color: #172554;
}

@media (max-width: 980px) {
  .mission-container {
    padding: 20px;
    margin-top: 60px;
    flex-direction: column;
  }
  .mission-card {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 600px; */
    /* background: #6ee7b7; */
    gap: 25px;
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    border: 2px solid #dfdbfe;
  }
}
