.hero-text {
  height: 70vh;
  width: 100%;
  background: linear-gradient(180deg, #e9d5ff, #fff);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -10;
}

.hero-text img {
  height: 100%;
  width: 100%;
}

.hero-text-container {
  color: #000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0px;
  height: 100%;
  width: 100%;
}

.hero-text-container h1 {
  font-size: 150px;
  text-align: center;
  background: linear-gradient(to right, #030712, #374151);
  color: transparent;
  -webkit-background-clip: text;
  /* text-shadow: 0px 0px 60px #3b82f6; */
  animation: text 4s infinite alternate;
}

.hero-text-container h3 {
  font-size: 30px;
}

.hero-text-container h3:last-child {
  /* font-family: "Fira Sans", sans-serif; */
}

@keyframes text {
  100% {
    text-shadow: 0px 0px 60px #4b5563;
  }
}

@media (max-width: 980px) {
  .hero-text-container h1 {
    font-size: 70px;
  }

  .hero-text-container h3 {
    font-size: 30px;
  }
}

/* =========SLIDER-SECTION============== */
.my-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 630px;
  width: 1000px;
}

.slider {
  height: 630px;
  width: 100%;
}

.each-slider-box {
  /* background: #000; */
  border: 2px solid #c8a143;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
}

.each-slider-box img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 980px) {
  .my-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 90%;
    margin-top: -130px;
    margin-bottom: 30px;
  }
  .slider {
    height: 250px;
    width: 100%;
  }
}

/* ======================== */

.panel {
  overflow: auto;
  width: 100%;
  height: 55vh;
  position: relative;
  background: radial-gradient(circle, lighten(#000, 5%) 15%, #000);
  background: linear-gradient(180deg, #e9d5ff, #fff);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid black; */
}

.jj {
  position: absolute;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin-top: 100px;
  text-align: center;
  font-size: 20vmin;
  color: #11141f;
  line-height: 0.85;
  text-transform: uppercase;
  background-image: url(https://anonimag.es/i/cee6d0f7-9dd3-430b-b9c3-bee2c52779b453d9f.jpg);
  background-size: contain;
  -webkit-text-fill-color: rgba(white, 0.1);
  -webkit-background-clip: text;
  z-index: 10;

  & + & {
    -webkit-text-fill-color: #c8a143;
    z-index: 20;
    animation: meh 3s ease-out infinite alternate;
  }

  span {
    display: block;
  }
}

@keyframes meh {
  from {
    clip-path: polygon(100% 0, 100% 100%, 20% 100%, 61% 91%, 94% 47%);
  }
  50% {
    clip-path: polygon(100% 0, 100% 100%, 20% 100%, 59% 52%, 74% 13%);
  }
  to {
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 85%, 87% 41%);
  }
}

@media (max-width: 980px) {
  .jj {
    font-size: 70px;
  }
}

.other-content {
  /* background: #000; */
  /* height: 250px; */
  width: 100%;
  margin-top: 220px;
}

.other-content h3 {
  text-align: center;
  font-size: 50px;
  color: #11141f;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.other-content h4 {
  text-align: center;
  font-size: 45px;
  color: #11141f;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

@media (max-width: 980px) {
  .other-content {
    /* background: #000; */
    /* height: 250px; */
    width: 100%;
    margin-top: 200px;
  }
  .other-content h3 {
    font-size: 30px;
    margin-top: 0px;
  }
  .other-content h4 {
    font-size: 30px;
  }
}
