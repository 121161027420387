.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #c8a143;
  transform-origin: bottom;
}
.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #c8a143;
  transform-origin: top;
}

body {
  font-family: "Bebas Neue", sans-serif;
}

p {
  text-align: justify;
}

/* ==========NAVBAR MAIN============== */
.navbar-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000000;
}

.navbar-main-details {
  background: #171c2a;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  padding-bottom: 60px;
}
.navbar-main-details ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.navbar-main-details ul li {
  font-size: 15px;
  color: #c8a143;
}
.navbar-main-details ul li p {
  color: gray;
}
.navbar-main-details h1 {
  color: #ddd;
}
.navbar-main-details h2 {
  color: #c8a143;
}
.company-name {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.nav-container-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000000;
}
.nav-container {
  width: 80%;
  background: #11141f;
  border: 2px solid #1f222e;
  position: absolute;
  padding: 10px 30px;
  border-radius: 50px;
}

.company-name h1 {
  border-right: 2px solid gray;
  padding-right: 10px;
}
.company-name h2 {
  font-family: "Aref Ruqaa", serif;
}

.nav-logo-div {
  height: 60px;
  width: 60px;
  background: #000;
  border: 2px solid #c8a143;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-logo-div img {
  height: 80%;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-menu ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.nav-menu ul li a {
  color: #eee;
}

.active {
  background: #c8a143;
  padding: 2px 10px;
  border-radius: 5px;
  color: #1f222e;
  width: 250px;
}

.menu-icon {
  display: none;
}

.md-screen-modal {
  position: absolute;
  background: #171c2a;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 5px;
  border: 2px solid #c8a143;
  width: 320px;
  display: none;
  z-index: 10000;
}

.md-screen-modal-container {
  position: relative;
  z-index: 1000;
}

.md-screen-modal-container ul {
  padding: 45px;
}

.md-screen-modal-container ul li {
  padding: 15px;
  width: 250px;
}
.md-screen-modal-container ul li a {
  color: #ddd;
}

.croxx-modal {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  background: #52d794;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 25px;
  cursor: pointer;
}

@media (max-width: 980px) {
  .company-name h1 {
    display: none;
  }
  .menu-icon {
    display: block;
    color: #c8a143;
    font-size: 35px;
  }
  .nav-menu {
    display: none;
  }
  .md-screen-modal {
    display: block;
  }
}

/* =============PROGRAM-SECTIONS============ */

.program-main {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.program-content {
  width: 800px;
}
.program-content h1 {
  font-size: 70px;
  text-align: center;
}
.program-content p {
  font-size: 30px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin-top: 30px;
}

.programs-image-container {
  width: 100%;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.program-image-card {
  width: 40%;
  height: 350px;
  border-radius: 20px;
  padding: 5px;
  border: 2px solid #dfdbfe;
}
.program-image-card img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.program-image-card:last-child {
  margin-top: 70px;
  border: 2px solid #172554;
}
@media (max-width: 980px) {
  .program-content {
    width: 100%;
    padding: 10px;
  }
  .program-content p {
    font-size: 25px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    margin-top: 30px;
  }
  .programs-image-container {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .program-image-card {
    width: 90%;
    height: 300px;
    border-radius: 20px;
    padding: 5px;
    border: 2px solid #dfdbfe;
  }
}

@media (max-width: 400px) {
  .program-image-card {
    width: 90%;
    height: 270px;
    border-radius: 20px;
    padding: 5px;
    border: 2px solid #dfdbfe;
  }
}


/* ========LOADING ANIMAITON=========== */

.loading-div {
  display: flex;
  align-items: center;
  justify-content: center;
}



/* ===============FAQ================= */

.main-faq{
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-faq h1{
  text-align: center;
  font-size: 35px;
  margin-bottom: 35px;
  text-decoration: underline;
}


.qustion-ans{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.qustion-ans details summary{
  background: #e1e1e1;
  margin-bottom: 30px;
  padding: 20px 10px;
  width: 540px;
  font-size: 30px;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #dfdbfe;
}

.qustion-ans details {
  width: 540px;
}


.qustion-ans details p{
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}



@media (max-width:980px) {
  .qustion-ans details {
    width: 100%;
  }
  .qustion-ans details summary{
    width: 100%;
    font-size: 20px;
  }
  .qustion-ans{
    width: 95%;
  }
}


/* ==========FOOTER================== */


.footer-image{
  height: 110px;
  width: 100px;
  margin-bottom: 30px;
}

@media (max-width:980px) {
  .footer-image{
    height: 90px;
    width: 80px;
    margin-bottom: 20px;
  }
}


.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 80px;
  background: #11141f;
}

.footer-two{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 80px;
  background: #11141f;
  margin-top: 60px;
  border-bottom: 2px solid #c8a143;
}

.footer-upper-icon{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-upper-icon p{
  color: #ddd;
  font-family: "Roboto", sans-serif;
}


.footer-upper-icon-rounded{
  height: 60px;
  width: 60px;
  background: #c8a143;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 10px;
}


.footer-contact-us {
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
}

.footer-contact-us h2 {
  font-size: 30px;
  color: #ddd;
}

.footer-contact-info a {
  color: #c8a143;
  text-decoration: underline;
  font-size: 25px;
}

.footer-icon h1 {
  font-size: 30px;
  color: #ddd;
}

.footer-icon ul {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}
.footer-icon ul li a {
  color: #c8a143;
  font-size: 25px;
}

.important-links h1 {
  font-size: 30px;
  color: #ddd;
}

.important-links ul li a {
  color: #c8a143;
  text-decoration: underline;
  font-size: 25px;
}

.human-resource {
  margin-bottom: 30px;
}

.human-resource h1 {
  color: #ddd;
  font-size: 40px;
}
.human-resource h2 {
  color: #ddd;
  font-size: 30px;
}
.human-resource h4 {
  color: #c8a143;
  text-decoration: underline;
}

.it-support h1 {
  color: #ddd;
  font-size: 30px;
}
.it-support h2 {
  color: #ddd;
  font-size: 25px;
}
.it-support h4 {
  color: #c8a143;
  text-decoration: underline;
  font-size: 25px;
}
.it-support p {
  color: #c8a143;
  font-family: "Roboto", sans-serif;
}

.mahafuz {
  margin-top: 30px;
}

.p{
  gap: 10px;
  font-size: 25px;
}



.footer-joinus{
  color: #000;
  padding: 2px 10px;
  background: #c8a143;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}


.footer-last{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #1f222e;
  flex-direction: row;
}

.footer-last p{
  color: #ddd;
  text-align: center;
}


.footer-icon-rounded{
  height: 50px;
  width: 50px;
  border: 2px solid #ddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  font-size: 25px;
}

@media (max-width: 980px) {
  .footer {
    justify-content: left;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 60px 40px;
    gap: 70px;
  }
  .footer-two{
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    gap: 10px;
    background: #11141f;
    margin-top: 60px;
    border-bottom: 2px solid #c8a143;
    width: 100%;
    padding: 30px 40px;
  }
  .footer-upper-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }

  .footer-upper-icon p{
    font-size: 15px;
  }

  .footer-upper-icon-rounded{
    height: 30px;
    width: 30px;
    background: #c8a143;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .footer-last{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: #1f222e;
    flex-direction: row;
  }
}









/* =================MAP============== */
.employeeForm{
  margin-top: 60px;
  text-align: center;
}

.map{
  width: 100%;
  height: 500vh;
}